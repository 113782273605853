<template>
    <div>
        <LinksEdit
            :item="currentItem"
            :update="updateItem"
            :close="closeEdit"
            :descSingular="descSingular"
            :paginas="paginas"
            :secoes="secoes"
        ></LinksEdit>
        <Loading :isActive="loading"></Loading>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab title="Topo">
                <LinksTable
                    :items="itemsTopo"
                    :openEdit="openEdit"
                    :openCreate="() => openCreate('topo')"
                    :deleteConfirm="deleteConfirm"/>
            </CTab>
            <CTab title="Rodapé">
                <LinksTable
                    :items="itemsRodape"
                    :openEdit="openEdit"
                    :openCreate="() => openCreate('rodape')"
                    :deleteConfirm="deleteConfirm"
                    :secoes="secoes"/>
            </CTab>
        </CTabs>
    </div>
</template>
<script>
  import clone from 'just-clone';
  import {del, get, post, put} from '@/helpers/apiRequest';
  import LinksEdit from './components/LinksEdit';
  import LinksTable from './components/LinksTable';
  import _filter from 'lodash/filter';

  const ItemLink = {
    id: null,
    titulo: null,
    link: null,
    ordem: 1,
    nova_aba: false,
    prefixo: null,
    descricao: null,
    imagem: null,
    tipo: 'Link Externo',
    ativo: true,
    secao_rodape_id: null,
  }

  export default {
    name: 'links',
    components: {
      LinksEdit,
      LinksTable,
    },
    data() {
      return {
        descPlural: '',
        descSingular: '',
        loading: false,
        itemsTopo: [],
        itemsRodape: [],
        modulos: [],
        currentItem: {},
        currentAccess: false,
        paginas: [],
        secoes: []
      };
    },
    methods: {
      loadDescricaoModulo() {
        const {modulos} = JSON.parse(localStorage.getItem('app'));
        const {descPlural, descSingular} = modulos.find(
          (m) => m.nome === 'ADMIN_LINK'
        );
        this.descPlural = descPlural;
        this.descSingular = descSingular;
      },
      loadLinks() {
        this.loading = true;
        get('/admin/site-links')
          .then((json) => {
            this.itemsTopo = _filter(json, {local: 'topo'});
            this.itemsRodape = _filter(json, {local: 'rodape'});
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      },
      loadSecoes() {
        get(`/admin/site-secoes-rodape`)
          .then((json) => {
            this.secoes = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      loadPaginas() {
        get(`/admin/site-paginas`)
          .then((json) => {
            this.paginas = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      openCreate(local) {
        this.currentItem = clone(ItemLink);
        this.currentItem.local = local;
        this.$modal.show('linkEdit');
      },
      closeCreate() {
        this.$modal.hide('linkEdit');
      },
      openEdit(item) {
        this.currentItem = clone(item);
        this.loading = false;
        this.$modal.show('linkEdit');
      },
      closeEdit() {
        this.$modal.hide('linkEdit');
      },
      doRequest() {
        const {id} = this.currentItem;
        return id
          ? put(`/admin/site-links/${id}`, this.currentItem)
          : post('/admin/site-links', this.currentItem);
      },
      updateItem() {
        this.doRequest()
          .then((json) => {
            this.closeEdit();
            this.loadLinks();
            this.$swal({
              title: 'Salvo com sucesso',
              text: json.message,
              showCancelButton: false,
              cancelButtonText: 'Fechar',
              showConfirmButton: true,
              icon: 'success',
            });
          })
          .catch((error) => {
            this.$swal({
              title: 'Falha ao Salvar',
              text: error.message,
              showCancelButton: true,
              cancelButtonText: 'Fechar',
              showConfirmButton: false,
              icon: 'error',
            });
          });
      },
      deleteConfirm(id) {
        this.$swal({
          title: 'Confirma Remover ?',
          text: 'Essa ação não poderá ser desfeita',
          showCancelButton: true,
          icon: 'warning',
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar',
        })
          .then((res) => {
            if (res.value) {
              this.loading = true;
              del(`/admin/site-links/${id}`)
                .then((json) => {
                  this.loading = false;
                  this.$swal({
                    title: 'Removido com sucesso',
                    icon: 'success',
                  });
                  this.loadLinks();
                })
                .catch((err) => {
                  this.loading = false;
                  this.$swal({
                    title: 'Falha ao remover',
                    icon: 'error',
                  });
                });
            }
          })
          .catch(() => {
          });
      },
    },
    beforeMount() {
      this.loading = true;
      this.loadLinks();
      this.loadDescricaoModulo();
      this.loadPaginas();
      this.loadSecoes();
    },
  };
</script>
